.card-register {
	background-color: #ffffff;
	border-radius: 8px;
	color: #000000;
	max-width: 450px;
	margin: 20px 35px 20px;
	min-height: 450px;
	padding: 30px;

	& label {
		margin-top: 15px;
	}
	& .card-title {
		color: #471503;
		text-align: center;
		font-weight: bolder;
	}
	& .btn {
		margin-top: 30px;
	}
	& .forgot {
		text-align: center;
	}
}
