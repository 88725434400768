Input.input-error {
	border-color: #fc8181;
}
.terms-section2 {
	border-radius: 30px;
	padding: 30px;
}
.btn-CreateStore {
	color: rgb(49, 49, 49);
	background-color: #487267 !important;
}
.signUpPageMain {
	background: rgb(244, 243, 255);
}
.public-business-picture {
	margin-top: -150px;
	position: relative;
	z-index: 9;
}
