body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffffff;
}
a,
a:hover,
a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sideBar {
	margin-top: 75px;
	width: 25%;
	position: fixed;
	z-index: 3;
}

@media only screen and (max-width: 720px) and (min-width: 320px) {
	.sideBar {
		position: absolute;
		width: 50%;
	}
}
.side-menu {
	height: calc(120vh - 110px);
	overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
	width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
	background: #5466b3;
	border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
	background: #3a4c99;
}

/* ---------------------------------------------------
    Business page / Product Section
----------------------------------------------------- */

.productPage-header {
	width: 100%;
}
/* Container for image previews */
.product-fileinput .thumbnail {
	position: relative;
	display: inline-block;
	margin: 5px;
}

/* Image preview styling */
.product-fileinput .thumbnail img {
	width: 50px;
	height: 50px;
	object-fit: cover; /* Ensures the image covers the container without stretching */
	border-radius: 5px; /* Optional: Adds rounded corners */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
	border: 1px solid #ddd; /* Adds a border to the image */
}

/* Error message styling */
.product-fileinput .errorMessage {
	color: red;
	font-size: 12px;
	margin-top: 10px;
}

/* Button styling within image input section */
.product-fileinput button {
	margin-top: 10px;
}

/* Adjust layout of image previews for larger screens */
@media (min-width: 768px) {
	.product-fileinput .thumbnail {
		margin: 10px;
	}
}
.editHeaderImageButton {
	margin-top: -100px;
	margin-left: 20px;
	z-index: 2;
	position: absolute;
}
.post-preview {
	position: relative;
	display: inline-block;
	margin: 10px;
}

/* Styling for the image preview */
.post-preview-img {
	width: 100px;
	height: 100px;
	object-fit: cover; /* Ensures the image covers the container without stretching */
	border-radius: 50%; /* Makes the image round */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
	border: 1px solid #ddd; /* Adds a border to the image */
}

/* Styling for the icons */
.post-preview .bi {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 1.5rem;
}

/* Positioning the left icon */
.post-preview .bi-x-circle {
	left: -25px;
	color: red;
}

/* Positioning the right icon */
.post-preview .bi-check-circle {
	right: -25px;
	color: green;
}
